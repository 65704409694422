// App.jsx
import {
    createPortal,
    useEffect,
    lazy,
    Suspense,
    useRef,
    createContext,
    useState,
    useContext,
    useCallback
} from '@wordpress/element';
import Shortcode from './Shortcode';
import LoadingSpinner from './components/LoadingSpinner';
import { getExistingCheckout, getRetailerId, scrollToShop, consoleDebug } from './util';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import ErrorComponent from './ErrorComponent';
import { categoryTypes, storageItemName } from './config';
import * as storage from './browserStorage';
import { ConfigContext } from './index.js';
import { useCartData, useRetailerData } from './hooks/dutchie-queries.js';
import { useCreateCart } from './hooks/dutchie-mutations.js';
import { Helmet } from 'react-helmet';
import Fallback from './Fallback.jsx';
import { useInitialURLParams } from './hooks/useInitialURLParams';

const CartSidebarIcon = lazy(() => import('./components/Cart/CartSidebarIcon'));
const CartSidebar = lazy(() => import('./components/Cart/CartSidebar'));
const LocationModal = lazy(() =>
    import('./components/LocationSelect/LocationModal')
);

export const RetailerIdContext = createContext(null);
export const ToggleSidebarContext = createContext(null);

const App = ({ shortcodeData, fallback }) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const initialParams = useInitialURLParams();
    const [currentParams, setCurrentParams] = useState(initialParams);
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        setCurrentParams(initialParams);
    }, [initialParams]);

    const [retailerId, setRetailerId] = useState(null);
    const [nonHeadlessRetailer, setNonHeadlessRetailer] = useState(storage.getItem('NON_HEADLESS_RETAILER'));
    const [shouldRenderCart, setShouldRenderCart] = useState(false);
    const [nonHeadlessRetailerData, setNonHeadlessRetailerData] = useState(null);
    const [sidebarToggle, setSidebarToggle] = useState(false);
    const [fallbackEnabled, setFallbackEnabled] = useState(false);
    const [ageGateVisible, setAgeGateVisible] = useState(true);
    const [locationRedirect, setLocationRedirect] = useState(false);

    const checkout = getExistingCheckout();

    const globalConfig = useContext(ConfigContext);
    const { retailers, nonHeadlessRetailers, locationGateConfig } = globalConfig;

    const { data: retailerData, isFetched: isFetchedRetailerData } = useRetailerData(retailerId);
    const cartCreatedRetailerId = useRef(false);
    const {
        data: cartData,
        isFetched: isFetchedCartData,
        isFetching: isFetchingCartData,
    } = useCartData(retailerId, Boolean(cartCreatedRetailerId !== retailerId));
    const handleCreateCart = useCreateCart(retailerId);

    // Move initialization logic to a dedicated effect
    useEffect(() => {
        const initializeApp = async () => {
            if (hasRetailerShortcode && nonHeadlessRetailer) {
                storage.removeItem('NON_HEADLESS_RETAILER');
                setNonHeadlessRetailer(null);
            } else {
                checkForNonHeadlessRetailer();
            }

            if (hasRetailerShortcode && !retailerId) {
                const retailerShortcode = Object.values(shortcodeData).find(
                    (data) =>
                        data.shortcode === 'headlessproducts' &&
                        data?.retailerShortcode?.providedId
                )?.retailerShortcode?.providedId;

                const newRetailerId = getRetailerId(retailerShortcode, params, retailers);
                if (newRetailerId) {
                    setRetailerId(newRetailerId);
                }
            }

            // After initialization is complete
            setIsInitialized(true);
        };

        initializeApp();
    }, [shortcodeData, retailers, params]);

    const hasRetailerShortcode = Object.values(shortcodeData).some(
        (data) =>
            data.shortcode === 'headlessproducts' &&
            data?.retailerShortcode?.providedId
    );

    const getPricingType = useCallback((retailerId) => {
        const currentRetailer = retailers[retailerId] || nonHeadlessRetailers.find(r => r.id === retailerId);
        return currentRetailer?.menuType === 'medical' ? 'MEDICAL' : 'RECREATIONAL';
    }, [retailers, nonHeadlessRetailers]);

    useEffect(() => {
        if (hasRetailerShortcode && nonHeadlessRetailer) {
            storage.removeItem('NON_HEADLESS_RETAILER');
            setNonHeadlessRetailer(null);
        } else {
            checkForNonHeadlessRetailer();
        }

        if (hasRetailerShortcode && !retailerId) {
            const retailerShortcode = Object.values(shortcodeData).find(
                (data) =>
                    data.shortcode === 'headlessproducts' &&
                    data?.retailerShortcode?.providedId
            )?.retailerShortcode?.providedId;

            const newRetailerId = getRetailerId(retailerShortcode, params, retailers);
            if (newRetailerId) {
                setRetailerId(newRetailerId);
            }
        }

        if (retailerId) {
            if (checkout === false && !handleCreateCart.isPending) {
                // No existing checkout, create a new cart
                cartCreatedRetailerId.current = retailerId;
                handleCreateCart.mutate({
                    retailerId,
                    orderType: 'PICKUP',
                    pricingType: getPricingType(retailerId),
                });
            } else if (checkout && checkout.retailerId !== retailerId) {
                // Existing checkout but retailerId has changed, update the cart
                storage.removeItem(storageItemName);
                handleCreateCart.mutate({
                    retailerId: retailerId,
                    orderType: 'PICKUP',
                    pricingType: getPricingType(retailerId),
                });
            }
        }
    }, [shortcodeData, nonHeadlessRetailer, retailerId, params, retailers, checkout, handleCreateCart.isPending]);

    const checkForNonHeadlessRetailer = () => {
        const currentPath = window.location.pathname;
        const isLocationPage = /^\/location\//.test(currentPath);

        if (isLocationPage && !hasRetailerShortcode) {
            const currentURL = window.location.href;
            const matchingRetailer = Object.values(nonHeadlessRetailers).find(retailer =>
                currentURL.startsWith(retailer.non_headless_location_homepage)
            );

            if (matchingRetailer) {
                storage.setItem('NON_HEADLESS_RETAILER', matchingRetailer.location_name);
                storage.removeItem(storageItemName);
                setNonHeadlessRetailer(matchingRetailer.location_name);
                setNonHeadlessRetailerData(matchingRetailer);
            }
        }
    };

    let retailerShortcode;

    if (nonHeadlessRetailer) {
        document.body.classList.add('non-headless-retailer');
        retailerShortcode = null;
        storage.removeItem(storageItemName);
    } else {
        document.body.classList.remove('non-headless-retailer');
        retailerShortcode = Object.values(shortcodeData).find(
            (data) =>
                data.shortcode === 'headlessproducts' &&
                data?.retailerShortcode?.providedId
        )?.retailerShortcode?.providedId;
        storage.removeItem('NON_HEADLESS_RETAILER');
    }

    // Only render the cart sidebar for these shortcodes (and if its a headless retailer).
    useEffect(() => {
        const hasValidShortcode = Object.values(shortcodeData).find(
            (data) =>
                data.shortcode === 'singleproduct' ||
                data.shortcode === 'headlessproducts' ||
                data.shortcode === 'headlessmenu' ||
                data.shortcode === 'shopnavfull'
        );

        setShouldRenderCart(!nonHeadlessRetailer && hasValidShortcode);
    }, [shortcodeData, nonHeadlessRetailer]);

    // URL param queries
    const searchQuery = new URLSearchParams(location.search).get('search');
    const thcMin = new URLSearchParams(location.search).get('thcMin');

    // Construct the query string
    const queryParams = new URLSearchParams();
    if (searchQuery) queryParams.append('search', searchQuery);
    if (thcMin) queryParams.append('thcMin', thcMin);

    // Convert query params to string and add '?' if there are any params
    const queryString = queryParams.toString();
    const queryStringWithQuestionMark = queryString ? `?${queryString}` : '';

    // If API has only one retailer or not.
    const isSingleRetailer = retailers && Object.keys(retailers).length === 1;

    /**
     * Navigate to menu immediately if only one shop.
     */
    useEffect(() => {
        if (
            isSingleRetailer &&
            params?.viewName === 'shop' &&
            typeof params.categoryName === 'undefined'
        ) {
            navigate(
                `/shop/${Object.values(retailers)[0].menuSlug}${queryStringWithQuestionMark}`,
                {
                    state: { setFilters: false },
                }
            );
        }
    }, []);

    /**
     * Check if retailerId is set in localStorage but URL doesn't contain shop slug.
     * Check if params have changed and set new retailer ID and create new cart.
     */
    useEffect(() => {
        consoleDebug('🛣️ Route Debug - Start');
        consoleDebug('Current URL:', window.location.href);
        consoleDebug('Route Params:', params);
        consoleDebug('RetailerId:', retailerId);
        consoleDebug('Retailers:', retailers);
    
        // Get query parameters
        const searchQuery = new URLSearchParams(location.search).get('search');
        const thcMin = new URLSearchParams(location.search).get('thcMin');
    
        // Construct the query string
        const queryParams = new URLSearchParams();
        if (searchQuery) queryParams.append('search', searchQuery);
        if (thcMin) queryParams.append('thcMin', thcMin);
        const queryString = queryParams.toString();
        const queryStringWithQuestionMark = queryString ? `?${queryString}` : '';
    
        // Check if current path is any of our valid category types
        const isValidCategoryPath = categoryTypes.includes(params?.locationSlug);
        consoleDebug('Category path check:', {
            locationSlug: params?.locationSlug,
            isValidCategoryPath
        });
    
        // Handle category-type paths (category, brand, tags, effect, specials)
        if (params?.viewName === 'shop' && isValidCategoryPath) {
            consoleDebug('🎯 Category-type path detected:', params.locationSlug);
            const checkout = getExistingCheckout();
            consoleDebug('Checkout:', checkout);
    
            if (checkout && retailers[checkout.retailerId]) {
                consoleDebug('Found existing checkout:', checkout);
                setRetailerId(checkout.retailerId);
                let url = `/shop/${retailers[checkout.retailerId]?.menuSlug}`;
                url += `/${params.locationSlug}/${params.categoryType}`;
                
                if (queryStringWithQuestionMark) {
                    url += queryStringWithQuestionMark;
                }
    
                consoleDebug('🔄 Redirecting to category path:', url);
                navigate(url, { replace: true });
                return;
            }
        }
    
        // Original retailerId management logic
        if (retailerData === undefined && !retailerId) {
            consoleDebug('Setting initial retailerId');
            const rId = isSingleRetailer
                ? Object.values(retailers)[0].id
                : getRetailerId(retailerShortcode, params, retailers);
    
            if (rId) {
                setFallbackEnabled(fallback[rId]?.enabled);
                setRetailerId(rId);
    
                if (
                    params?.viewName === 'shop' &&
                    !params.categoryName &&
                    params.categoryType !== 'specials'
                ) {
                    const menuSlug = retailers[rId]?.menuSlug;
    
                    if (!menuSlug) {
                        consoleDebug('Warning: No menu slug available for retailer:', rId);
                        return;
                    }
    
                    navigate(
                        `/shop/${menuSlug}${queryStringWithQuestionMark}`,
                        {
                            state: { setFilters: false },
                        }
                    );
                }
            }
        }
    
        // Handle invalid shop URLs without clearing retailerId
        if (params?.viewName === 'shop' && 
            params?.locationSlug && 
            params.locationSlug !== 'undefined' &&
            !isValidCategoryPath) {
            
            consoleDebug('🔍 Checking for valid retailer slug');
            const isValidRetailerSlug = Object.values(retailers).some(
                retailer => retailer.menuSlug === params.locationSlug
            );
    
            if (!isValidRetailerSlug) {
                consoleDebug('❌ Invalid URL detected');
                // If we have a retailerId, redirect to their menu
                if (retailerId && retailers[retailerId]?.menuSlug) {
                    consoleDebug('Redirecting to current retailer menu');
                    navigate(`/shop/${retailers[retailerId].menuSlug}/`, {
                        state: { setFilters: false },
                        replace: true
                    });
                } else {
                    // Only if we don't have a retailerId, go to base shop
                    consoleDebug('No retailer found, redirecting to base shop');
                    navigate('/shop/', {
                        replace: true
                    });
                }
                return;
            }
        }
    
        // Handle retailer changes for multi-retailer setups
        if (
            retailers &&
            Object.keys(retailers).length > 1 &&
            params?.locationSlug &&
            params.locationSlug !== 'undefined' &&
            !isValidCategoryPath
        ) {
            const newRetailerId = getRetailerId(
                retailerShortcode,
                params,
                retailers
            );
    
            if (
                retailerId !== null &&
                newRetailerId !== false &&
                retailerId !== newRetailerId &&
                !fallbackEnabled
            ) {
                setFallbackEnabled(fallback[newRetailerId]?.enabled);
                setRetailerId(newRetailerId);
                handleCreateCart.mutate({
                    retailerId: newRetailerId,
                    orderType: 'PICKUP',
                    pricingType: getPricingType(newRetailerId),
                });
            }
        }
    
        // Handle base /shop/ redirects
        if (params?.viewName === 'shop' &&
            (!params?.locationSlug || params.locationSlug === 'undefined')) {
            
            if (retailerId && retailers[retailerId]?.menuSlug) {
                navigate(`/shop/${retailers[retailerId].menuSlug}/${queryStringWithQuestionMark}`, {
                    state: { setFilters: false },
                    replace: true
                });
                return;
            }
        }
    
        consoleDebug('🛣️ Route Debug - End');
    }, [params, retailerId, retailers, location.search, retailerData]);

    /**
     * Check for age gate session to force rendering if not passed.
     */
    useEffect(() => {
        if (
            locationGateConfig &&
            locationGateConfig.enableAgeGate &&
            storage.getItem('HEADLESS_AGE_GATE_PASSED') !== 'true'
        ) {
            setAgeGateVisible(true);
        }
    }, [locationGateConfig]);

    /**
     * Debug retailer data.
     */
    const retailerDataLogged = useRef(false);
    const cartDataLogged = useRef(false);

    useEffect(() => {
        // Log retailer data when available and not logged yet
        if (isFetchedRetailerData && retailerData && !retailerDataLogged.current) {
            consoleDebug('🏪 Retailer Data Loaded:', {
                id: retailerData.id,
                name: retailerData.name,
                location: {
                    city: retailerData.address?.city,
                    state: retailerData.address?.state
                },
                menuType: retailerData.menuType,
                status: retailerData.status,
                fullObject: retailerData
            });
            retailerDataLogged.current = true;
        }

        // Log cart data when available and not logged yet
        if (isFetchedCartData && cartData && !cartDataLogged.current) {
            consoleDebug('🛒 Cart Data Loaded:', {
                id: cartData.id,
                itemCount: cartData.items?.length || 0,
                total: cartData.total,
                orderType: cartData.orderType,
                items: cartData.items?.map(item => ({
                    id: item.id,
                    name: item.product?.name,
                    quantity: item.quantity
                })),
                fullObject: cartData
            });
            cartDataLogged.current = true;
        }
    }, [retailerData, cartData, isFetchedRetailerData, isFetchedCartData]);

    if (!isInitialized) {
        return <LoadingSpinner />;
    }

    // emergency fallback.
    if (fallbackEnabled && retailerId) {
        return (
            <>
                {Object.entries(shortcodeData).map(([rootId, dataset], i) => {
                    let root = document.getElementById(rootId);

                    if (i === 0) {
                        return (
                            <Fallback
                                key={rootId}
                                rootId={rootId}
                                shortcodeData={dataset}
                                script={fallback[retailerId].script}
                            />
                        );
                    } else {
                        return createPortal(
                            <Fallback
                                key={rootId}
                                rootId={rootId}
                                shortcodeData={dataset}
                                script={fallback[retailerId].script}
                            />,
                            root
                        );
                    }
                })}
            </>
        );
    }
    // if there's retailers data but no retailer ID set, it means a location has to be chosen.
    else if (
        ((retailers &&
            !retailerId &&
            Object.keys(retailers).length > 1 &&
            ageGateVisible &&
            !fallbackEnabled) ||
            (locationRedirect && !retailerId)) &&  // Only consider locationRedirect if we don't have a retailerId
        !nonHeadlessRetailer
    ) {
        return createPortal(
            <ErrorBoundary
                showDialog
                fallback={(props) => <ErrorComponent {...props} />}
            >
                <Suspense fallback={<LoadingSpinner />}>
                    <Helmet>
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <LocationModal
                        retailers={retailers}
                        setRetailerId={setRetailerId}
                        setNonHeadlessRetailer={setNonHeadlessRetailer}
                        nonHeadlessRetailerData={nonHeadlessRetailerData}
                        setNonHeadlessRetailerData={setNonHeadlessRetailerData}
                        ageGateVisible={ageGateVisible}
                        setAgeGateVisible={setAgeGateVisible}
                        locationRedirect={locationRedirect}
                        setLocationRedirect={setLocationRedirect}
                    />
                </Suspense>
            </ErrorBoundary>,
            document.body
        );
    }
    // if there's retailers data and a retailer ID set, we're good to load the main app. 
    else if (isFetchedRetailerData && cartData !== null && !fallbackEnabled || nonHeadlessRetailer) {
        return (
            <>
                <Helmet>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <RetailerIdContext.Provider value={retailerId}>
                    <ToggleSidebarContext.Provider
                        value={{ sidebarToggle, setSidebarToggle }}
                    >
                        <ErrorBoundary
                            showDialog
                            fallback={(props) => <ErrorComponent {...props} />}
                        >
                            {Object.entries(shortcodeData).map(
                                ([rootId, dataset], i) => {
                                    let root = document.getElementById(rootId);

                                    if (i === 0) {
                                        return (
                                            <Shortcode
                                                key={rootId}
                                                rootId={rootId}
                                                shortcodeData={dataset}
                                                setRetailerId={setRetailerId}
                                                nonHeadlessRetailer={nonHeadlessRetailer}
                                                setNonHeadlessRetailer={setNonHeadlessRetailer}
                                                forcedOrderType={initialParams}
                                                locationGateConfig={locationGateConfig}
                                                ageGateVisible={ageGateVisible}
                                                setAgeGateVisible={setAgeGateVisible}
                                                locationRedirect={locationRedirect}
                                                setLocationRedirect={setLocationRedirect}
                                                nonHeadlessRetailerData={nonHeadlessRetailerData}
                                                setNonHeadlessRetailerData={setNonHeadlessRetailerData}
                                                retailerId={retailerId}
                                                retailers={retailers}
                                                fallbackEnabled={fallbackEnabled}
                                            />
                                        );
                                    } else {
                                        return createPortal(
                                            <Shortcode
                                                key={rootId}
                                                rootId={rootId}
                                                shortcodeData={dataset}
                                                setRetailerId={setRetailerId}
                                                nonHeadlessRetailer={nonHeadlessRetailer}
                                                setNonHeadlessRetailer={setNonHeadlessRetailer}
                                                forcedOrderType={initialParams}
                                                locationGateConfig={locationGateConfig}
                                                ageGateVisible={ageGateVisible}
                                                setAgeGateVisible={setAgeGateVisible}
                                                locationRedirect={locationRedirect}
                                                setLocationRedirect={setLocationRedirect}
                                                nonHeadlessRetailerData={nonHeadlessRetailerData}
                                                setNonHeadlessRetailerData={setNonHeadlessRetailerData}
                                                retailerId={retailerId}
                                                retailers={retailers}
                                                fallbackEnabled={fallbackEnabled}
                                            />,
                                            root
                                        );
                                    }
                                }
                            )}
                            {shouldRenderCart && createPortal(
                                <ErrorBoundary
                                    showDialog
                                    fallback={(props) => (
                                        <ErrorComponent {...props} />
                                    )}
                                >
                                    <Suspense fallback={<LoadingSpinner />}>
                                        <CartSidebarIcon fixedCart={true} />
                                        <CartSidebar
                                            fixedCart={true}
                                            forcedOrderType={initialParams}
                                        />
                                    </Suspense>
                                </ErrorBoundary>,
                                document.body
                            )}
                        </ErrorBoundary>
                    </ToggleSidebarContext.Provider>
                </RetailerIdContext.Provider>
            </>
        );
    }

    return <LoadingSpinner />;
};

export default App;
